
class EventSection {
  modal = document.querySelector('.md')
  eventSubmit = document.querySelector('.eventSubmit')
  hiddenTourTitle = document.querySelector('.hiddenTourTitle')
  eventTitle = document.querySelector('.eventTitle')
  serviceType = document.querySelector('.serviceType')
  eventEmail:any = document.querySelector('.eventEmail')
  modalEmail:any = document.querySelector('.email')
  hiddenDate:any = document.querySelector('.hiddenDate')
  hiddenNumber:any = document.querySelector('.hiddenNumber')

  constructor() {
    this.eventSubmit.addEventListener('click', () => {
      this.serviceType.setAttribute('value', 'Събитие')
      this.modalEmail.setAttribute('value', this.eventEmail.value)

      this.hiddenDate.setAttribute('value', '2000-01-01')
      this.hiddenNumber.setAttribute('value', '0')
      this.hiddenTourTitle.setAttribute('value', this.eventTitle.innerHTML)
    })
  }

}

new EventSection;